<template>
  <section>
    <div class="module fx computer">
      <div class="module_class">
        <div class="module_header fa">
          <img
            src="../../assets/images/class_back.png"
            class="module_back"
            alt=""
          />
          <div class="module_data">
            <div class="module_title">新闻动态</div>
            <div class="module_en">NEWS</div>
          </div>
        </div>
        <div
          v-for="(item, index) in classList"
          :key="index"
          @click="tableClick(index)"
          class="module_text"
          :class="item.status ? 'module_active' : ''"
        >
          <span v-show="$i18n.locale == 'zh'">{{ item.name }}</span>
          <span v-show="$i18n.locale == 'en'">{{ item.name_en }}</span>
        </div>
      </div>
      <div class="f1">
        <div v-if="!detailStatus">
          <div
            v-for="(item, index) in dataList"
            :key="index"
            @click="detailClick(item.id)"
            class="module_item fa"
          >
            <div class="module_date fa">
              <div>
                <div class="module_day">{{ item.day }}</div>
                <div class="module_month">
                  {{ item.month }}
                  <span v-show="$i18n.locale == 'zh'">月</span>
                  <span v-show="$i18n.locale == 'en'">month</span>
                </div>
                <div class="module_year">
                  {{ item.year }}
                  <span v-show="$i18n.locale == 'zh'">年</span>
                  <span v-show="$i18n.locale == 'en'">year</span>
                </div>
              </div>
            </div>
            <div class="f1">
              <div class="module_news h1">
                <span v-show="$i18n.locale == 'zh'">{{ item.title }}</span>
                <span v-show="$i18n.locale == 'en'">{{ item.title_en }}</span>
              </div>
              <div class="module_content h2">
                <div v-show="$i18n.locale == 'zh'" v-html="item.content"></div>
                <div
                  v-show="$i18n.locale == 'en'"
                  v-html="item.content_en"
                ></div>
              </div>
            </div>
          </div>
          <div class="page faj">
            <div class="fx">
              <div
                v-for="(item, index) in total"
                :key="index"
                class="page_item faj"
                @click="changePage(index + 1)"
                :class="p == index + 1 ? 'page_active' : ''"
              >
                {{ index + 1 }}
              </div>
            </div>
          </div>
        </div>
        <div v-if="detailStatus" class="detail">
          <a-icon @click="detailClick" class="detail_back" type="arrow-left" />
          <div>
            <div class="detail_title">
              <span v-show="$i18n.locale == 'zh'">{{ dataInfo.title }}</span>
              <span v-show="$i18n.locale == 'en'">{{ dataInfo.title_en }}</span>
            </div>
            <div class="detail_date">{{ dataInfo.createtime }}</div>
            <div class="detail_text">
              <div
                v-show="$i18n.locale == 'zh'"
                v-html="dataInfo.content"
              ></div>
              <div
                v-show="$i18n.locale == 'en'"
                v-html="dataInfo.content_en"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="phone">
      <div class="classP">
        <div class="faj">
          <div class="fx wp">
            <div
              v-for="(item, index) in classList"
              :key="index"
              @click="tableClick(index)"
              class="classP_table faj"
              :class="item.status ? 'classP_active' : ''"
            >
              <span v-show="$i18n.locale == 'zh'">{{ item.name }}</span>
              <span v-show="$i18n.locale == 'en'">{{ item.name_en }}</span>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!detailStatus" class="moduleP">
        <div
          v-for="(item, index) in dataList"
          :key="index"
          @click="detailClick(item.id)"
          class="module_item fa"
        >
          <div class="module_date fa">
            <div>
              <div class="module_day">{{ item.day }}</div>
              <div class="module_month">
                {{ item.month }}
                <span v-show="$i18n.locale == 'zh'">月</span>
                <span v-show="$i18n.locale == 'en'">month</span>
              </div>
              <div class="module_year">
                {{ item.year }}
                <span v-show="$i18n.locale == 'zh'">年</span>
                <span v-show="$i18n.locale == 'en'">year</span>
              </div>
            </div>
          </div>
          <div style="overflow: hidden" class="f1">
            <div class="moduleP_news h2">
              <span v-show="$i18n.locale == 'zh'">{{ item.title }}</span>
              <span v-show="$i18n.locale == 'en'">{{ item.title_en }}</span>
            </div>
            <div class="module_content h2">
              <div v-show="$i18n.locale == 'zh'" v-html="item.content"></div>
              <div v-show="$i18n.locale == 'en'" v-html="item.content_en"></div>
            </div>
          </div>
        </div>
        <div class="page faj">
          <div class="fx">
            <div
              v-for="(item, index) in total"
              :key="index"
              class="page_item faj"
              @click="changePage(index + 1)"
              :class="p == index + 1 ? 'page_active' : ''"
            >
              {{ index + 1 }}
            </div>
          </div>
        </div>
      </div>
      <div v-if="detailStatus" class="detailP">
        <a-icon @click="detailClick" class="detailP_back" type="arrow-left" />
        <div>
          <div class="detail_title">
            <span v-show="$i18n.locale == 'zh'">{{ dataInfo.title }}</span>
            <span v-show="$i18n.locale == 'en'">{{ dataInfo.title_en }}</span>
          </div>
          <div class="detail_date">{{ dataInfo.createtime }}</div>
          <div class="detail_text">
            <div v-show="$i18n.locale == 'zh'" v-html="dataInfo.content"></div>
            <div
              v-show="$i18n.locale == 'en'"
              v-html="dataInfo.content_en"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import qs from "qs";
import {
  get_article_category,
  get_article_list,
  get_article_info,
} from "@/assets/api/api";
export default {
  data() {
    return {
      detailStatus: false,
      classList: "",
      p: 1,
      total: "",
      dataList: "",
    };
  },
  methods: {
    detailClick(id) {
      if (this.detailStatus) {
        this.detailStatus = false;
      } else {
        get_article_info(qs.stringify({ article_id: id })).then((res) => {
          if (res.data.code == 1) {
            this.detailStatus = true;
            this.dataInfo = res.data.data;
          } else {
            this.$message.error(res.data.msg);
          }
        });
      }
    },
    changePage(num) {
      this.p = num;
      let id = "";
      for (let i = 0; i < this.classList.length; i++) {
        if (this.classList[i].status) {
          id = this.classList[i].id;
        }
      }
      this.get_article_list(id);
    },
    tableClick(index) {
      this.p = 1;
      for (let i = 0; i < this.classList.length; i++) {
        this.classList[i].status = false;
      }
      this.classList[index].status = true;
      this.get_article_list(this.classList[index].id);
      this.detailStatus = false;
      this.$forceUpdate();
    },
    get_article_category() {
      get_article_category().then((res) => {
        if (res.data.code == 1) {
          this.classList = res.data.data;
          this.get_article_list(res.data.data[0].id);
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    get_article_list(id) {
      get_article_list(qs.stringify({ p: this.p, cate_id: id })).then((res) => {
        if (res.data.code == 1) {
          this.dataList = res.data.data.data;
          this.total = res.data.data.last_page;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    backTop() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
  },
  mounted() {
    this.get_article_category();
    if (this.$route.query.id) {
      this.detailClick(this.$route.query.id);
    }
    this.backTop();
  },
};
</script>

<style scoped>
.detailP {
  position: relative;
  padding: 30px 15px 15px 15px;
}
.detailP_back {
  font-size: 20px;
  position: absolute;
  left: 10px;
  top: 10px;
  cursor: pointer;
}
.moduleP {
  position: relative;
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
}
.moduleP_news {
  font-size: 16px;
  color: #333;
}
.classP {
  position: relative;
  padding: 10px 15px;
  background: #f6f6f6;
  border-bottom: 1px solid #f0f0f0;
}
.classP_table {
  height: 32px;
  padding: 0 10px;
  background: #fff;
  font-size: 14px;
  color: #333;
  margin: 10px;
  border-radius: 16px;
}
.classP_active {
  color: #fff;
  background: #004e9f;
}

.module {
  padding: 50px 0;
  width: 1200px;
  margin: 0 auto;
}
.module_class {
  position: relative;
  width: 220px;
  margin-right: 55px;
}
.module_header {
  position: relative;
  width: 220px;
  height: 85px;
}
.module_back {
  position: absolute;
  height: 100%;
  width: 100%;
}
.module_data {
  position: relative;
  padding: 0 15px;
  width: 100%;
  box-sizing: border-box;
}
.module_title {
  font-size: 17px;
  color: #fff;
}
.module_en {
  font-size: 13px;
  color: #fff;
}
.module_text {
  padding: 15px;
  background: #f6f6f6;
  font-size: 14px;
  cursor: pointer;
  color: #333;
}
.module_active {
  background: #e8e8e8;
  color: #004e9f;
}

.module_item {
  margin-bottom: 25px;
  cursor: pointer;
}
.module_date {
  width: 110px;
  height: 110px;
  background: #eaeaea;
  margin-right: 15px;
}
.module_day {
  padding: 0 10px;
  font-weight: bold;
  font-size: 32px;
  color: #004e9f;
  width: 100%;
  box-sizing: border-box;
}
.module_month {
  padding: 0 10px;
  font-size: 17px;
  color: #666;
  width: 100%;
  box-sizing: border-box;
}
.module_year {
  padding: 0 10px;
  font-size: 17px;
  color: #666;
  margin-top: 3px;
  width: 100%;
  box-sizing: border-box;
}
.module_news {
  font-size: 26px;
  color: #333;
}
.module_content {
  font-size: 14px;
  margin-top: 12px;
  color: #999;
  overflow: hidden;
}

.detail {
  position: relative;
  padding-left: 50px;
}
.detail_back {
  font-size: 20px;
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;
}
.detail_title {
  font-weight: bold;
  font-size: 44px;
  color: #333;
}
.detail_date {
  font-size: 17px;
  color: #666;
  margin-top: 10px;
}
.detail_text {
  font-size: 16px;
  color: #333;
  margin-top: 20px;
}

.page {
  padding: 30px;
}
.page_item {
  height: 45px;
  width: 45px;
  color: #fff;
  font-size: 17px;
  margin: 0 5px;
  border-radius: 5px;
  cursor: pointer;
  background: #ddd;
}
.page_active {
  background: #004e9f;
}
</style>